import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function HaderHome() {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="bg-header-home">
      <div className="relative md:pt-[150px] pt-[200px] pb-[60px] md:w-4/5 w-full mx-auto flex md:flex-row flex-col p-6">
        <div className="md:w-1/2 w-full md:text-start text-center">
          <h1 className=" md:text-start text-center text-white">
            {rpdata?.dbSlogan?.[1].slogan}
          </h1>
          <p className="md:text-start text-center text-white">
            {rpdata?.dbValues?.[0].description}
          </p>
          <a
            className={`
                        boton
                        my-3
                        one
                        px-6
                        py-4
                        w-52
                        rounded-lg
                        bg-white
                        text-red-500
                    `}
            href="/contact"
            onClick={goToTop}
          >
            <span className="capitalize">Free Estimate</span>
          </a>
        </div>
        <div className="md:w-1/2 w-full p-4 text-center">
          <div className="md:w-full md:h-[800px] w-full md:mt-[-270px] md:mb-[-220px] md:ml-[300px]">
            <div className="w-full h-full flex justify-center align-middle items-center">
              <img
                src={rpdata?.gallery?.[5]}
                alt="Company Logo"
                className="mx-auto md:w-full md:mr-[500px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HaderHome;
